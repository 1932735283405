import React from "react";
export function SectionEnd() {

return(
    <>
    <div className="sectionend">
        <div className="rocketimg"  ></div>
        <div className="thanks"> Thanks for reading ! </div>
        
    </div>
    <div className="contact"  > <a href="https://www.linkedin.com/in/monalussa/">Contact me</a> </div>
    </>
    
)

}