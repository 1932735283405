import React from 'react';
import lussagif from './images/lussa.gif';
import { SectionTerminal } from './SectionTerminal';

export class SectionParallaxBody extends React.Component {

  constructor(props) {
    super(props);
    this.handleScrollY = this.handleScrollY.bind(this);
  }

  componentDidMount() {
    document.addEventListener("scroll", this.handleScrollY);
  }

  handleScrollY(event) {
    const distance = window.scrollY;
    document.querySelector(".container").style.transform = `translate(0px, ${distance * 0.6}px)`;
  }

  render() {

    return (
      <main>
        <div className="sectionone">
          <div className="container">
            <img src={lussagif} className="lussasign" alt="" />
          </div>
        </div>
        <div className="sectiontwo">
          {<SectionTerminal />}
        </div>

      </main>
    );
  }
}
