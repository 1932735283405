import React from 'react';
import { useRef, useState, useEffect } from 'react';
import HTMLFlipBook from "react-pageflip";


export function SectionHorizontal() {
  const bookref = useRef(null);
  const sectionthreeref =  useRef(null);
  const sectionisVisible = useIntersection(sectionthreeref, "0px");
  const containerSectionthree = sectionthreeref.current;
  const [flipPageWidth, setFlipPageWidth] = useState("");
  const [flipPageHeight, setFlipPageHeight] = useState("");
  const [fontsize, setFontsize] = useState("1.7rem");

    useEffect(() => {
    if (window.innerWidth > 1300) {
                    setFlipPageWidth("550");
                    setFlipPageHeight("710");

                } else {
                    setFlipPageWidth("170");
                    setFlipPageHeight("220");
                    setFontsize("0.7rem")
                }
    }, []);




  function handleScrollY(event) {
    const distance = window.scrollY - containerSectionthree.offsetTop;
    document.querySelector(".sectionthree").style.transform = 
    `translate(0px, ${distance * (-0.8)}px)`;
  }




  React.useEffect(() => {
    if (sectionisVisible) {
      document.addEventListener("scroll", handleScrollY);
    }
  }, [handleScrollY, sectionisVisible]);




  return (
    <>
    <div className="sectionthree" ref={sectionthreeref}>
      <div style={{ width: flipPageWidth * 2, height: flipPageHeight, 
      border: 0.5, fontSize: fontsize}}
      className='bookcontainer bg-primary-100 pointer-events-auto border' >
        <HTMLFlipBook 
            style={{
              minHeight: 0,
             height: flipPageHeight,
           }}
        width={flipPageWidth} height={flipPageHeight} 
        autoSize="false"
        size="stretch"
        flippingTime="500" 
        showSwipeHint="true"
        maxShadowOpacity={1}
        ref={bookref}>
        <div  className='leftContainer' id="first" style={{ left: "0px"}}> </div>
          <div className='rightContainer' id="first" style={{left: "0px"}}></div>

          <div  className='leftContainer' id="second" style={{ left: "0px"}}> </div>
          <div  className='rightContainer' id="second" style={{ left: "0px"}}>
          <h4>Stavanger, Norway, 3 years </h4>
                <br />
                Worked for a startup company, <br />
                contributed to javascrip, php, java, IOT. <br />
                <br />
                A diverse software that was, <br />
                doing things like accounting, bookings, <br />
                even managing orders of pizzas. <br />
                 <br />
                Time to switch to React came to be, <br />
                so I set myself to study what I want for me. <br />

          </div>

          <div  className='leftContainer' id="third" style={{ left: "0px"}}>
              <h4>Antibes, France, 1 year </h4>
                <br /><br />There was a big company in travel industry <br />I learned more python, groovy, <br />
                but corona started, it was time to flee. <br />
             </div>
          <div  className='rightContainer' id="third" style={{ left: "0px"}}></div>

          <div  className='leftContainer' id="forth" style={{ left: "0px"}}> </div>
          <div  className='rightContainer' id="forth" style={{ left: "0px"}}>
              <h4>Luxembourg, 1 year   & Zagreb, 4 years</h4>
                <br /><br />Fun it was to talk to satellites from raspberry PIs,<br />
                and to work with satellite equipment otherwise.<br /><br />
                Fun it was to work in a bank as well,<br />
                there was java spring booting,<br />
                very nice colleagues,<br />we were as close as fellowship of the ring.<br />
                </div>
          
          <div  className='leftContainer' id="fifth" style={{ left: "0px"}}> <br /> <h4>The End</h4> </div>



          
        </HTMLFlipBook>
      </div>
     </div>
    </>

  );

}

export const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const current = element?.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );
    current && observer?.observe(current);

    return () => current && observer.unobserve(current);
  }, []);

  return isVisible;
};


export function  detectMouseWheelDirection( e )
{
    var delta = null,
        direction = false
    ;
    if ( !e ) { // if the event is not provided, we get it from the window object
        e = window.event;
    }
    if ( e.wheelDelta ) { // will work in most cases
        delta = e.wheelDelta / 60;
    } else if ( e.detail ) { // fallback for Firefox
        delta = -e.detail / 2;
    }
    if ( delta !== null ) {
        direction = delta > 0 ? 'up' : 'down';
    }

    return direction;
}


