import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { SectionParallaxBody } from './SectionParallaxBody';
import { SectionHorizontal } from './SectionHorizontal';
import { SectionEnd } from './SectionEnd';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SectionParallaxBody />
    <SectionHorizontal/>
    <SectionEnd />
  </React.StrictMode>
);




