import React from 'react';
import { useState, useEffect, useRef } from 'react';

export function SectionTerminal() {

  const [shownText, setShownText] = useState('');
  const [arrayToShow, setArrayToShow] = useState([]);
  const [letterCount, setLetterCount] = useState(1);
  const mymessage = "I am a developer. But design is a fun part as well. # I always carry my superpowers with me. Those are # java, javascript, python, shell scripting, # graphical design, a bicycle and a paintbrush.";
  const ref = useRef();
  const isVisible = useIsVisible(ref);
  const [underscoreVisible, setUnderscoreVisible] = useState(true);

  // Console text
  useEffect(() => {
    setTimeout(() => {
      if (isVisible) {
        setShownText((shownText + mymessage.substring(letterCount - 1, letterCount)));
        setLetterCount(letterCount + 1);
        setArrayToShow(shownText.split("#"));
      }

    }, 35);
  });
  // Underscore blinking
  useEffect(() => {
    setTimeout(() => {
      if (underscoreVisible === true) {
        setUnderscoreVisible(false);

      } else {
        setUnderscoreVisible(true);
      }
    }, 400);
  });

  return <>
    <div className="console-container" ref={ref}>

      {isVisible ? arrayToShow.map((line, i) => {

        if (i < (arrayToShow.length - 1)) {
          return <p className="line" id={i} key={i} style={{ display: 'block' }}>{line}</p>;
        } else {
          return <p className="line" id={i} key={i}>{line}</p>;
        }

      }) : ""}  <p className='console-underscore'> {underscoreVisible ? `_` : ` `}</p>


    </div>
  </>;
}



export const   useIsVisible = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}